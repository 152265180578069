export default function payBalance(teamEvent) {
  let payVal = 'Unpaid Balance';
  if (teamEvent) {
    if (teamEvent.refundCompleted) {
      payVal = 'Refund Complete';
    } else if (teamEvent.refundCancelled) {
      payVal = 'Refund Cancelled';
    } else if (teamEvent.refundFailed) {
      payVal = 'Refund Failed';
    } else if (teamEvent.refundDenied) {
      payVal = 'Refund Denied';
    } else if (teamEvent.refundPending) {
      payVal = 'Refund Pending';
    } else if (teamEvent.refundRequested) {
      payVal = 'Refund Requested';
    } else if (teamEvent.paid || teamEvent.manuallyPaid) {
      payVal = 'Paid';
    } else if (teamEvent.manualPayment) {
      payVal = 'Paying Directly';
    } else {
      payVal = 'Unpaid';
    }
  }
  return payVal;
}
